// Hat-tip to https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set

// Set intersection. new Set([1,2,3]).intersection(new Set([2,3,4])) gives you a set with [2,3]
Set.prototype.intersection = function(otherSet) {
  let _intersection = new Set()
  for(let elem of otherSet) {
    if (this.has(elem)) {
      _intersection.add(elem)
    }
  }
  return _intersection
}

// Set union. new Set([1,2,3]).intersection(new Set([2,3,4])) gives you a set with [1,2,3,4]
Set.prototype.union = function(otherSet) {
  let _union = new Set(this)
  for(let elem of otherSet) {
    _union.add(elem)
  }
  return _union
}

// Set difference. new Set([1,2,3]).difference(new Set([2,3,4])) gives you a set with [1]
Set.prototype.difference = function(otherSet) {
  let _difference = new Set(this)
  for (let elem of otherSet) {
      _difference.delete(elem)
  }
  return _difference
}
