// Ensure that <script> tags sent via cable_ready morphs will execute. In particular, our charts come with a <script>
// tag that does the render.
document.addEventListener("cable-ready:after-morph", (event) => {
  var scripts = event.srcElement.querySelectorAll('script')
  scripts.forEach(script => {
    const scriptNode = document.createElement('script');
    scriptNode.text = script.innerHTML;
    script.parentNode.replaceChild(scriptNode, script);
  })
})
