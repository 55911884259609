import CableReady from 'cable_ready'
import consumer from "./consumer"

let reconnecting = false

consumer.subscriptions.create('SessionChannel', {
  initialized () {
    this.reconnect = this.reconnect.bind(this)
    if(consumer.connection.disconnected) {
      this._disableReflexElements()
    }
    this._disabledReflexLinkClickHandler = this._disabledReflexLinkClickHandler.bind(this)
  },

  received (data) {
    if (data.cableReady)
      CableReady.perform(data.operations, {
        emitMissingElementWarnings: false
      })
  },

  connected () {
    this._enableReflexElements()
    reconnecting = false
    document.addEventListener('reconnect', this.reconnect)
  },

  disconnected () {
    this._disableReflexElements()
    document.removeEventListener('reconnect', this.reconnect)
    if (reconnecting) setTimeout(() => {
      consumer.connect()
      // Event for ReconnectionController
      setTimeout(() => document.dispatchEvent(new Event('reconnected')), 25)
    }, 25)
  },

  reconnect () {
    reconnecting = true
    consumer.disconnect()
  },

  _disableReflexElements () {
    Array.from(document.querySelectorAll("[data-reflex]")).forEach((elem) => {
      // The hiddenSpan changes the text of the element, so capybara tests won't find it until the websocket reconnects
      let hiddenSpan = document.createElement("span")
      hiddenSpan.classList.add("hidden")
      hiddenSpan.textContent = "stalled, waiting for websocket to connect"
      elem.setAttribute("data-actioncable-reconnect-html", elem.innerHTML)
      elem.innerHTML = elem.innerHTML + window.spinnerTemplate
      elem.appendChild(hiddenSpan)
      elem.classList.add("stalled")
      elem.addEventListener('click', this._disabledReflexLinkClickHandler)
    })
  },

  _enableReflexElements () {
    Array.from(document.querySelectorAll("[data-reflex]")).forEach((elem) => {
      // This function gets called on the initial connect, as well as on reconnects. In the initial connect, there is no saved data-actioncable-reconnect-html.
      const innerHtml = elem.getAttribute("data-actioncable-reconnect-html")
      if(innerHtml) {
        elem.innerHTML = innerHtml
        elem.removeAttribute("data-actioncable-reconnect-html")
      }
      elem.removeEventListener('click', this._disabledReflexLinkClickHandler)
      elem.classList.remove("stalled")
      if(elem.getAttribute("data-actioncable-reconnect-clicked") === "true") {
        elem.removeAttribute("data-actioncable-reconnect-clicked")
        setTimeout(() => elem.click(), 25) // Tiny delay to let the callbacks finish, at which point the connection will be live
      }
    })
  },

  // Trap incoming clicks, and save them for when the websocket reconnects
  _disabledReflexLinkClickHandler (event) {
    event.stopPropagation()
    event.preventDefault()
    event.currentTarget.setAttribute("data-actioncable-reconnect-clicked", "true")
  }
})
