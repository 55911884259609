const hideParent = el => {
  el.parentElement.classList.add('hidden')
}

// Instrument all page loads
document.addEventListener("turbo:load", () => {
  const elements = document.querySelectorAll('[data-hide-parent-on-click]')
  Array.from(elements).forEach(el => {
    el.addEventListener('click', () => { hideParent(el) })
  })
})

// Instrument flash messages specifically (because they can show up from the background tier via cable_ready, without
// an accompanying turbo:load event)
document.addEventListener("turbo:load", () => {
  const flashMessagesElem = document.querySelector('#flash_messages')
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if(mutation.type === 'childList') {
        const newElements = flashMessagesElem.querySelectorAll('[data-hide-parent-on-click]')
        Array.from(newElements).forEach(el => {
          el.addEventListener('click', () => { hideParent(el) })
        })
      }
    })
  })

  observer.observe(flashMessagesElem, { childList: true })
})
