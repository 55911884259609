// Hack for UJS confirm behavior. By default, UJS only fires a confirm:complete event, and that fires when the user
// accepts the confirmation and when they cancel the confirmation. In order to use the confirmation to trigger a reflex
// using only data attributes, i.e. without calling a custom Stimulus controller, we need to have succes & failure events.
// This code catches the confirm:complete, and based on its paylod fires confirm:success or confirm:failure events on
// the originating element. The confirm:complete event stores the results in the detail attribute's first element.
// Because somebody hates developers.
document.addEventListener('confirm:complete', function(event) {
  if(event.detail[0]) {
    // User confirmed!
    event.target.dispatchEvent(new Event('confirm:success'))
  } else {
    // User cancelled
    event.target.dispatchEvent(new Event('confirm:failure'))
  }
})
