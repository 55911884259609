// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

// Global JS extensions
require('utilities/cable_ready')
require('utilities/confirmation_success')
require('utilities/hiding')
require('utilities/html_to_elements')
require('utilities/set')
require('utilities/trix')

// ActionText support
require("trix")
require("@rails/actiontext")

// Enable Datatables
require('datatables.net-dt');
require('datatables.net-buttons-dt');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-colreorder-dt');


// Load up Turbo & Stimulus controllers
import "controllers"

// Enable fancy date & time pickers in forms
import '../components/date_time_picker'

// Enable charts
import 'chartkick/chart.js'
import { Chart, LinearScale, PointElement } from 'chart.js'
window.Chart = Chart
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation'
import { TreeController, EdgeLine } from 'chartjs-chart-graph';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';


Chart.register(annotationPlugin, TreeController, EdgeLine, LinearScale, PointElement, MatrixController, MatrixElement);

// Welcome message!
console.log('\\ \\ / /__  __ _ _ __ / _ \\ _ __   ___ ')
console.log(' \\ V / _ \\/ _` | \'__| | | | \'_ \\ / _ \\')
console.log('  | |  __/ (_| | |  | |_| | | | |  __/')
console.log('  |_|\\___|\\__,_|_|   \\___/|_| |_|\\___|')
console.log("Hello, code spelunker! Welcome to YearOne!")
console.log("\n\n")
